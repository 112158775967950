import { memo } from 'react'
import LOGO from 'src/images/logo.png'
const Header = ({ marketplaceConfig }) => {
  return (
    <header>
      <img
        style={{
          width: marketplaceConfig?.width,
          height: marketplaceConfig?.height
        }}
        src={marketplaceConfig?.logo || LOGO} alt="orders.co" />
      <p id="siteUrl"></p>
    </header>
  )
}

export default memo(Header)
