import { memo } from "react";
const OrderHeader = ({ orderData, marketplaceConfig }) => {
  const TYPES = {
    pickup: "Pick-up",
    delivery: "Delivery",
  };

  const SCHEDULE = {
    scheduled: "Scheduled",
  };

  return (
    <div id="receiptHeader">
      <div id="receiptHeaderInfo">
        {marketplaceConfig?.logo && (
          <img
            style={{
              width: marketplaceConfig?.width,
              height: marketplaceConfig?.height,
              paddingBottom: '24px'
            }}
            src={marketplaceConfig?.logo} alt="orders.co" />
        )}
        <p className="companyName">
          {orderData?.business[0]?.title || orderData?.business[0]?.companyName}
        </p>
        <hr
          style={{
            marginBottom: "16px",
            marginTop: "16px",
          }}
        />
        <p className="user-message">
          {`${
            orderData?.contactInfo?.firstName || orderData?.contactInfo?.name
          }!`}
        </p>
        <p className="order-number">{`Order #${orderData.number}`}</p>
        <hr
          style={{
            marginBottom: "16px",
            marginTop: "16px",
          }}
        />
        <div
          style={{
            fontSize: "24px",
            lineHeight: "26px",
            letterSpacing: "0.1px",
            display: "flex",
            justifyContent: "center",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          <p>{TYPES[orderData?.type]}</p>
          {orderData?.isScheduled ? <p>{`(${SCHEDULE.scheduled})`}</p> : <></>}
        </div>
        <hr
          style={{
            marginTop: "16px",
          }}
        />
      </div>
    </div>
  );
};
export default memo(OrderHeader);
